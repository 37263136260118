<template>
  <div class="xone-dataobjects">
    <template v-for="breadcumb of routeBreadcumbs" :key="breadcumb.id">
      <div
        :id="`obj-${breadcumb.id}`"
        class="xone-dataobject"
        v-show="breadcumb === lastBreadcumb"
      >
        <Coll v-if="!breadcumb.isSearch" :breadcumb="breadcumb"></Coll>
        <LinkedSearchObject v-else :breadcumb="breadcumb"></LinkedSearchObject>
      </div>
    </template>
  </div>
  <div
    v-if="showBreadcumbs"
    style="position: absolute; background: white; bottom: 0"
  >
    <template v-for="breadcumb in routeBreadcumbs" :key="breadcumb.id"
      ><span>&nbsp;&nbsp;/&nbsp;&nbsp;{{ breadcumb.name }}</span></template
    ><span>&nbsp;&nbsp;</span>
  </div>
</template>

<script>
import Coll from "@/components/Coll";
import LinkedSearchObject from "@/components/LinkedSearchObject";
import { inject, watch, ComputedRef } from "vue";
import AppDataHandler, { Breadcumb } from "../composables/AppDataHandler";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Coll,
    LinkedSearchObject,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    /**
     * @type {ComputedRef<Breadcumb[]>}
     */
    const routeBreadcumbs = inject("routeBreadcumbs");

    /**
     * @type {ComputedRef<Breadcumb>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    /**
     * @type {function():Promise<void>}
     */
    const loginFunction = inject("loginFunction");

    const doLogin = () => {
      AppDataHandler.clearBreadcumbs();
      // Create login XoneDataObject
      loginFunction();
    };

    // Change route depending on the last breadcumb
    watch(
      () => lastBreadcumb.value.id,
      () => {
        // if (!lastBreadcumb.value)
        //   setTimeout(() => !lastBreadcumb.value && doLogin(), 50);
        const type = lastBreadcumb.value?.type;
        if (type !== "Login")
          router.push({
            name: "Coll",
            query: { id: lastBreadcumb.value.id },
          });
      }
    );

    // Control history route modifications
    watch(
      () => route.query,
      (newValue) => {
        if (newValue.id && lastBreadcumb.value.type === "Coll")
          AppDataHandler.clearBreadcumbsFrom(newValue.id);

        if (route.name === "Login" && lastBreadcumb.value.type !== "Login")
          doLogin();
      }
    );

    return {
      routeBreadcumbs,
      lastBreadcumb,
      showBreadcumbs: inject("showBreadcumbs"),
    };
  },
};
</script>