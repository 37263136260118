<template>
  <div>{{ breadcumb }}</div>
</template>

<script>
export default {
  props: {
    breadcumb: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>